/* eslint-disable import/prefer-default-export */

import store from "@/redux/store";
import { mainAxiosInstance } from "./networkAdapters/axiosInstance";

const offerSendLock = {
  locked: false
};

export function sendJobOfferApi(data) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post(`offer/connect`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in sendJobOfferApi", e.response);
        reject(e);
      });
  });
}
export function sendJobOfferApiNew(data) {
  return new Promise((resolve, reject) => {
    if (offerSendLock.locked) return;
    offerSendLock.locked = true;
    mainAxiosInstance
      .post(`/offer/free-flow/unlock-from-search`, data)
      .then((res) => {
        resolve(res);
        offerSendLock.locked = false;
      })
      .catch((e) => {
        console.error("Error in sendJobOfferApi", e.response);
        offerSendLock.locked = false;
        reject(e);
      });
  });
}
export function respondToJobOfferApi(data) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post("/offer/connect/action", data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in respondToJobOfferApi", e.response);
        reject(e);
      });
  });
}

export function createJobListingApi(data) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post(`/offer/free-flow/create-job`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in createJobListingApi", e.response);
        reject(e);
      });
  });
}

export const fetchJobsCountApi = (data) =>
  new Promise((resolve, reject) => {
    if (data) {
      mainAxiosInstance
        .post("/offer/filterCount", data)
        .then((res) => {
          if (res.data.status === 200) {
            resolve(res.data.data);
          }
          reject(res.data.message);
        })
        .catch((e) => {
          console.log("Error in fetchJobsCountApi api", e.response);
          reject(e);
        });
    } else {
      mainAxiosInstance
        .get("/offer/feed/count")
        .then((res) => {
          if (res.data.status === 200) {
            resolve(res.data.data);
          }
          reject(res.data.message);
        })
        .catch((e) => {
          console.log("Error in fetchJobsCountApi api", e.response);
          reject(e);
        });
    }
  });

export function fetchJobsUnfilteredApi(pageNum, limit = 10) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(`/offer/feed?offset=${pageNum}&limit=${limit}&typeId=`)
      .then((res) => {
        if (pageNum === 1) {
          fetchJobsCountApi(null).then((count) => {
            resolve({ ...res, data: { ...res.data, count } });
          });
        } else resolve(res);
      })
      .catch((e) => {
        console.error("Error in fetchJobsUnfilteredApi", e.response);
        reject(e);
      });
  });
}
export function fetchRecommendedJobsApi(pageNum, limit = 10) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(`/offer/recommended?page=${pageNum}&limit=${limit}`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in fetchRecommendedJobsApi", e.response);
        reject(e);
      });
  });
}
export function fetchSimilarJobsApi(offerId, pageNum, limit = 10) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(
        `/offer/similar_job?page=${pageNum}&limit=${limit}&offerId=${offerId}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in fetchJobsUnfilteredApi", e.response);
        reject(e);
      });
  });
}
export function fetchParticularOfferApi(offerId) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(`/offer/getParticularOffer?offerId=${offerId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in fetchParticularOfferApi", e.response);
        reject(e);
      });
  });
}
export function fetchJobsFilteredApi(searchData) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post(`/offer/filtered`, searchData)
      .then((res) => {
        if (searchData.offset === 1) {
          fetchJobsCountApi(searchData).then((count) => {
            resolve({ ...res, data: { ...res.data, count } });
          });
        } else resolve(res);
      })
      .catch((e) => {
        console.error("Error in fetchJobsFilteredApi", e.response);
        reject(e);
      });
  });
}
export function applyToJobOfferApi(offerId,applySource, config,) {
  return new Promise((resolve, reject) => {
    const axiosConfig = {
      ...config,
      params: {
        ...config?.params,
        applySource,
      },
    };
    mainAxiosInstance
      .post(`/offer/${offerId}/apply_to_job`, {}, axiosConfig || {})
      .then((res) => {
        resolve(res);
        if (res.status === 200) {
          const { user_id: userId } = store.getState().user;
          window.fbq("trackCustom", "Applied to Job", {
            userId,
            offerId
          });
        }
      })
      .catch((e) => {
        console.error("Error in fetchJobsFilteredApi", e.response);
        reject(e);
      });
  });
}

export function getFeedBackFromShortlister(offerId, config) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(`/offer/${offerId}/get_feed_back`, {}, config || {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in fetchJobsFilteredApi", e.response);
        reject(e);
      });
  });
}
export function updateJobPostApi(data) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .put(`/offer`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in updateJobPostApi", e.response);
        reject(e);
      });
  });
}
export function getSentOffersApi(page, limit = 10) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(`/offer/getOffersSent?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in getSentOffersApi", e.response);
        reject(e);
      });
  });
}
export function getReceivedOffersApi(page, limit = 10) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(`/offer/getOffersRecieved?page=${page}&limit=${limit}`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in getReceivedOffersApi", e.response);
        reject(e);
      });
  });
}
export function saveJobApi(offerId) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post(`/offer/saveJob`, { offerId })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in saveJobApi", e.response);
        reject(e);
      });
  });
}
export function unsaveJobApi(offerId) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post(`/offer/unsaveJob`, { offerId })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in unsaveJob", e.response);
        reject(e);
      });
  });
}
export function deleteCardApi(type, id) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post(`/member/hide_card`, { type, id })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in deleteCardApi", e.response);
        reject(e);
      });
  });
}
export function deleteCardApiHirer(type, memberId, id) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post(`/hirer/hide_card`, { type, memberId, id })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in deleteCardApiHirer", e.response);
        reject(e);
      });
  });
}
export function reportJobApi(offerId, reason) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post(`/offer/report`, { offerId, reason })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in reportJobApi", e.response);
        reject(e);
      });
  });
}
